import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import AccessControlPage from "components/access-control/AccessControlPage";

export default function AccessControlLandingPage() {
  return (
    <GlobalLayout dark color="transparent">
      <Helmet>
        <title>Commercial Door Access Control Systems | Rhombus</title>
        <meta
          name="description"
          content="Enable centralized management, remote access, and streamlined visitor management with Rhombus commercial door access control system. Request a demo today."
        />
      </Helmet>
      <AccessControlPage />
    </GlobalLayout>
  );
}
