import AccordionSection from "components/common/AccordionSection";
import DeviceCards from "components/common/DeviceCards";
import AccessControlBanner from "components/common/footer-banners/AccessControlBanner";
import InfoSlider from "components/common/InfoSlider";
import PlatormModule from "components/common/modules/PlatormModule";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

import AccessControlHero from "./AccessControlHero";
import AccessControlVideo from "./AccessControlVideo";

export default function AccessControlPage() {
  const renderContent = data => {
    const slides = {
      title: "A Modern Approach to Building Management",
      cards: [
        {
          name: "DC20 Access Control Unit",
          description:
            "Works with existing door hardware and operates during power outages.",
          link: "/access-control/dc20/",
          image: data.dc20,
        },
        {
          name: "DR40 Video Intercom",
          description: "All-in-one access control with real-time visibility.",
          link: "/access-control/door-readers/dr40/",
          image: data.dr40,
        },
        {
          name: "DR20 Door Reader",
          description:
            "Get instant access with the wave of your hand, mobile device, or key card.",
          link: "/access-control/door-readers/dr20/",
          image: data.dr20,
        },
        {
          name: "Secure Card",
          description:
            "High Frequency Card with advanced authentication and encryption features.",
          link: "/access-control/secure-cards/",
          image: data.secureCards,
        },
      ],
    };

    const accordianSection = {
      title: "Streamline Building and Guest Management",
      items: [
        {
          title: "Native Video and Two-Way Audio",
          p:
            "Get maximum visibility on arrivals and departures by pairing access events with live footage. Get notified when visitors arrive, see who’s at the door, and talk to guests all from one device.",
          img: data.slide1,
          alt:
            "Rhombus DR40 intercom  next to a door with a text bubble saying someone will meet you shortly",
        },
        {
          title: "Touchless or Keycard Entry",
          p:
            "Gain instant access to buildings with a wave of your hand, tap of your finger, or the touch of a keycard. Rhombus’ Door Reader’s fast and reliable performance ensures smooth access for guests and employees.",
          img: data.slide2,
          alt: "Rhombus DR20 door reader next to a door entrance to a building",
        },
        {
          title: "Operational During Power Outages",
          p:
            "Never worry when the power goes out. Rhombus Access Control provides uninterrupted service during outages, ensuring building security is maintained in all conditions.",
          img: data.slide3,
          alt: "Rhombus dc20 mounted on a wall",
        },
        {
          title: "All-In-One Management",
          p:
            "Oversee unlimited doors and users, set access schedules, manage lockdown plans, and orchestrate sophisticated workflows between access control, cameras, sensors, and alarms—all from one user-friendly dashboard.",
          img: data.slide4,
          alt: "Diagram of Rhombus' features",
        },
      ],
    };

    const keyAppSection = {
      title: "Unlock Doors with the Rhombus Key App",
      list: [
        {
          tabTitle: "Smartphone App",
          title: "Mobile Credentials for Easy Access",
          description:
            "Securely unlock doors straight from your smartphone. Building access is fast and simple—no more keeping track of physical keys, keycards, or badges. ",
          image: data.keyApp1,
        },
        {
          tabTitle: "Easy Access",
          title: "Remote Unlock and Wave-to-Unlock ",
          description:
            "Badging in is a breeze with three ways to unlock doors: select a door in the app, place your phone next to the door reader, or go hands-free and simply wave your hand for fast and secure entry.",
          image: data.keyApp2,
        },
        {
          tabTitle: "Swipe & Favorite",
          title: "Save Frequent Doors ",
          description:
            "Put your most-used doors front and center within the Rhombus Key app. Your favorites are saved for quick and easy access.",
          image: data.keyApp3,
        },
        {
          tabTitle: "Centralized Management",
          title: "Multi-Location Access",
          description:
            "Need to access multiple buildings or locations? No problem—securely unlock all your doors from one simple login.",
          image: data.keyApp4,
        },
      ],
    };

    const cards = [
      {
        image: data.card1,
        title: "Security Cameras",
        blurb:
          "Take the guesswork out of who’s coming and going. Capture synchronized video of access events.",
        link: { path: "/cameras/", text: "Cloud Cameras" },
      },
      {
        image: data.card2,
        title: "Computer Vision & AI Alerts  ",
        blurb:
          "Automatically detect and receive real-time access alerts via human movement detection and facial recognition.",
        link: { path: "/ai-analytics/", text: "AI Analytics " },
      },
      {
        image: data.card3,
        title: "Investigations ",
        blurb:
          "Investigate using smart search and an intuitive video timeline where access events are automatically marked.",
        link: { path: "/console/", text: "Console" },
      },
      {
        image: data.card4,
        title: "After-Hours Security ",
        blurb:
          "Cross-reference entry data in real time to reduce false alarms and enable faster and more accurate emergency response.",
        link: { path: "/alarm-monitoring/", text: "Alarm Monitoring" },
      },
    ];

    return (
      <>
        <AccessControlVideo />
        <DeviceCards data={slides} deviceType="Access Control" center />
        <AccessControlHero />
        <AccordionSection
          data={accordianSection}
          fancy={false}
          color="var(nuetral-100)"
        />
        <InfoSlider data={keyAppSection.list} title={keyAppSection.title} />
        <PlatormModule
          cards={cards}
          title="Leverage Access Control in your physical security ecosystem"
          blurb="Designed to connect with everything Rhombus for scalable security that’s more than the sum of its parts"
        />
        <AccessControlBanner color="var(--nuetral-100)" />
      </>
    );
  };
  const GET_IMAGES = graphql`
    query {
      dc20: file(
        relativePath: { eq: "components/access-control/img/dc20-500-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      dr40: file(
        relativePath: { eq: "components/access-control/img/dr40-500-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      dr20: file(
        relativePath: { eq: "components/access-control/img/dr20-500-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      secureCards: file(
        relativePath: {
          eq: "components/access-control/specs/img/secure-cards-hero-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      slide1: file(
        relativePath: {
          eq: "components/access-control/img/slide-1-1000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide2: file(
        relativePath: {
          eq: "components/access-control/img/slide-2-1000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide3: file(
        relativePath: {
          eq: "components/access-control/img/operational-power-outages-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide4: file(
        relativePath: {
          eq: "components/access-control/img/slide-4-1000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp1: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/smartphone-app-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp2: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/easy-access-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp3: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/swipe-and-fav-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp4: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/centralized-management-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card1: file(
        relativePath: { eq: "components/common/modules/img/cameras-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card2: file(
        relativePath: {
          eq: "components/common/modules/img/ai-analytics-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card3: file(
        relativePath: {
          eq: "components/common/modules/img/investigations-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card4: file(
        relativePath: {
          eq: "components/common/modules/img/alarm-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
