import SlideInContainer from "components/common/SlideInContainer";
import PageContent from "components/page/PageContent";
import { MainParagraph, Title } from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { css } from "linaria";
import React from "react";

const sectionClass = css`
  width: 100%;
  height: 100%;
  min-height: 570px;
  display: grid;
`;

const contentWrapper = css`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  position: relative;
`;

const videoTextClass = css`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  height: 100%;
  color: var(--white);
  @media (max-width: 700px) {
    width: 100%;
    row-gap: unset;
    align-items: center;
  }
  > div {
    max-width: 495px;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
  h1 {
    @media (max-width: 700px) {
      width: 100%;
    }
  }
`;

const pageContentClass = css`
  height: 100%;
`;

export default function AccessControlHero() {
  const renderContent = data => {
    const images = withArtDirection(getImage(data.webHeader), [
      {
        media: "(max-width: 700px)",
        image: getImage(data.mobileHeader),
      },
    ]);
    return (
      <section className={sectionClass}>
        <GatsbyImage
          image={images}
          alt=""
          style={{
            gridArea: "1/1",
            pointerEvents: "none",
            filter: "brightness(0.6)",
          }}
          loading="eager"
          objectPosition="40%"
        />
        <div className={contentWrapper}>
          <PageContent contentClass={pageContentClass}>
            <div className={videoTextClass}>
              <SlideInContainer>
                <Title color="var(--white)">
                  Unify Building Management Like Never Before
                </Title>
              </SlideInContainer>
              <SlideInContainer>
                <MainParagraph>
                  Pair seamlessly with the Rhombus Platform for all-in-one
                  security at scale. Manage all aspects of your spaces from a
                  single pane of glass to streamline operations, eliminate
                  complexity, and improve safety.
                </MainParagraph>
              </SlideInContainer>
            </div>
          </PageContent>
        </div>
      </section>
    );
  };
  const GET_IMAGES = graphql`
    query {
      webHeader: file(
        relativePath: {
          eq: "components/access-control/img/dc20-beauty-shot.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileHeader: file(
        relativePath: {
          eq: "components/access-control/img/dc20-beauty-shot.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
